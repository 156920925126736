import { getRuntimeConfig } from ".";
import { isObject } from "../tools/utils";
import { parseLegacyRuntimeConfig } from "./legacy";
import { Application } from "./types";
import { getAppVersion } from "./utils";

/**
 * The app's runtimeConfig definition is stored on the API so that it can be
 * modified via the backoffice. This config is required before we can bootstrap
 * the app so it needs to be loaded first.
 *
 * When running locally the config is loaded from the local filesystem.
 */
export const loadRuntimeConfig = async (): Promise<void> => {
  try {
    const { collaboardApiUrl } = window;

    /**
     * @TODO #7992 - Remove this check once env.js has been updated with the
     * new schema. This value will only be missing when using the legacy env.js
     *
     * Until then we fallback to the legacy runtime config from env.js so that
     * the backoffice can still be accessed.
     */
    if (!collaboardApiUrl) {
      throw new Error("env.js does not contain collaboardApiUrl");
    }

    await fetchRuntimeConfig(collaboardApiUrl);
    validateRuntimeConfig();
  } catch (error) {
    console.error(error);

    /**
     * @TODO #7992 - Remove this once env.js has been updated with the
     * new schema.
     */
    console.warn(
      "GetClientSettings not configured - falling back to /config/env.js"
    );
    window.dynamicRuntimeConfig = parseLegacyRuntimeConfig(
      window.runtimeConfig
    );
  }
};

/**
 * @NOTE The config is embedded as a javascript file (JSONP) so that we can use
 * the same approach for both local and remote config, while maintaining TS
 * coverage of the local config file.
 */
const fetchRuntimeConfig = (apiUrl: string): Promise<void> => {
  const host = window.location.host;
  const useLocalConfig =
    host.startsWith("localhost") || host.startsWith("192.168.");

  // Fail fast if required server configuration is not set
  if (!useLocalConfig && !apiUrl.trim()) {
    throw new Error("apiUrl is empty");
  }

  const configUrl = useLocalConfig
    ? "/config/env.local.js" // <--- this is used when developing locally
    : `${apiUrl}/api/CollaborationHub/GetClientSettings`;

  if (useLocalConfig) {
    // eslint-disable-next-line no-console
    console.warn(`Loading runtimeConfig from ${configUrl}`);
  }

  const version = getAppVersion();

  return loadInjectedScript(
    `${configUrl}?application=${Application.CollaBoardBackOffice}&client=${host}&version=${version}`
  );
};

/**
 * Basic validation to ensure that the config has loaded and the schema looks
 * broadly correct.
 *
 * The config coming from the API is outside of the control of the app, so
 * mistakes will be possible. Therefore we need to make sure that the app fails
 * quickly rather than relying on / waiting for an exception to occur in the
 * underlying code.
 */
const validateRuntimeConfig = (): void => {
  /**
   * Validate the response from getRuntimeConfig() instead of
   * window.dynamicRuntimeConfig directly to ensure that we are validating the same
   * object that the rest of the app consumes.
   */
  const runtimeConfig = getRuntimeConfig();

  if (!runtimeConfig) {
    throw new Error("window.dynamicRuntimeConfig not found");
  }

  if (!runtimeConfig.env || !runtimeConfig.appId || !runtimeConfig.storage) {
    throw new Error("window.dynamicRuntimeConfig missing required property");
  }

  if (!isObject(runtimeConfig.storage)) {
    throw new Error("window.dynamicRuntimeConfig.storage is not an object");
  }

  if (runtimeConfig.application !== "CollaBoardBackOffice") {
    throw new Error("window.dynamicRuntimeConfig.application is incorrect");
  }
};

export const loadInjectedScript = (src: string): Promise<void> => {
  return new Promise((resolve, reject) => {
    const script = document.createElement("script");
    script.async = true;
    script.type = "text/javascript";
    script.src = src;

    script.onerror = (err) => {
      reject(err);
    };

    script.onload = () => {
      resolve();
    };

    document.body.appendChild(script);
  });
};

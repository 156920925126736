import { isDate as _isDate } from "date-fns";
import { isNil } from "ramda";

export const stringToArray = (value: string): string[] => {
  return value
    .split(",")
    .map((value) => value.trim())
    .filter((value) => !!value); // Remove empty strings
};

// used as array.filter(isDefined), where:
// input array is (T | undefined)[]
// output array is T[]
export const isDefined = <T>(t: T | undefined | null): t is T => !isNil(t);

export const isObject = (value: unknown): value is Record<string, unknown> => {
  return typeof value === "object" && !Array.isArray(value) && value !== null;
};

/**
 * Wrapping the date-fns isDate function to have a more specific type guard
 */
export const isDate = (value: unknown): value is Date => {
  return _isDate(value) ? true : false;
};

export const isNumber = (value: unknown): value is number => {
  return Number.isFinite(value);
};

type SplitBy = {
  <T, U extends T>(values: T[], predicate: (value: T) => value is U): [
    U[],
    Exclude<T, U>[]
  ];
  <T>(values: T[], predicate: (value: T) => boolean): [T[], T[]];
};

/**
 * Split a group into two subgroups according to a predicate
 *
 * @example
 *
 * ```ts
 * const [yes, no] = splitBy([1, 2, 3], x => x < 2)
 * ```
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const splitBy: SplitBy = (values: any[], predicate: any) => {
  return values.reduce(
    (result, value) => {
      const [yes, no] = result;

      predicate(value) ? yes.push(value) : no.push(value);

      return result;
    },
    [[], []]
  );
};

export const isString = (variable: unknown): variable is string => {
  return typeof variable === "string" || variable instanceof String;
};

export const noop = (): void => {};
